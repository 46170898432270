import React from 'react'
import { graphql } from 'gatsby'
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import get from 'lodash/get'

import Blogpost from '../templates/blogpost'
import Job from '../templates/job'
import Page from '../templates/page'

import Layout from '../components/layout'
import SliceList from '../components/sliceList'
import Seo from '../components/seo'

export const query = graphql`
  query page404($lang: String!) {
    prismicPage(uid: { eq: "404" }, lang: { eq: $lang }) {
      ...PrismicPageFragment
    }
    prismicNavigation(lang: { eq: $lang }) {
      ...PrismicNavigationFragment
    }
    prismicSocial(lang: { eq: $lang }) {
      ...PrismicSocialFragment
    }
  }
`

const NotFoundPage = (props) => {
  const pageData = props.data.prismicPage.data
  const navigation = props.data.prismicNavigation
  const social = props.data.prismicSocial

  return (
    <Layout altLang={props.data.prismicPage.alternate_languages} navigation={navigation} social={social}>
      <Seo title={pageData.meta_title ? (pageData.meta_title):(pageData.title)} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={props.pageContext.lang} />
      <div className='text-center text-black z-10 relative flex flex-col justify-center mt-10'>
        {pageData.page_title && (
          <div className='md:text-6xl text-5xl' dangerouslySetInnerHTML={{__html:get(pageData, 'page_title.html')}} />
        )}
        {pageData.body !== null && pageData.body.length > 0 && pageData.body.map((slice, index) => {
          return (
            <div className='pb-5' key={`slice-${index}`}>
              <SliceList slice={slice} />
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    blog_post: Blogpost,
    job_post: Job,
    page: Page,
  },
})
